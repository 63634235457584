/**
 * 휴일배송 플래그 노출 페이지 리스트
 * 전시영역은 전 영역 노출(HDV-24)
 * 상품상세 장바구니 담기 후 바텀시트 내 추천상품, 장바구니 내 추천상품 추가 노출 (QAH-631)
 * @typedef {String[]} showHolidayFlagPathList
 */

/** @type {showHolidayFlagPathList} */
const showHolidayFlagUrlList = [
  'ds',
  'SCR_PDR_0001',
  'SCR_ORC_0001',
  'SCR_ORA_0006'
]

/**
 * 휴일배송 노출 가능여부 제공 함수
 * @function
 * @param {String} path - 페이지 경로
 */
export function isShowHolidayFlag (path) {
  return showHolidayFlagUrlList.some(element => path.includes(element))
}
