import * as http from 'http'
import * as https from 'https'
import { setupCache } from 'axios-cache-adapter'
import axios from 'axios'
import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
// import _ from 'lodash'

let forageStore
class AxiosBuilderCache {
  config = {
    baseURL: process.env.API_SSN_BASE_URL2,
    headers: {},
    params: {},
    withCredentials: true,
    httpAgent: new http.Agent({
      keepAlive: true,
      keepAliveMsecs: 300000,
      scheduling: 'lifo',
      maxSockets: 128,
      maxFreeSockets: 128,
      timeout: 60000,
      freeSocketTimeout: 30000
    }),
    httpsAgent: new https.Agent({
      keepAlive: true,
      keepAliveMsecs: 300000,
      scheduling: 'lifo',
      maxSockets: 128,
      maxFreeSockets: 128,
      timeout: 60000,
      freeSocketTimeout: 30000,
      rejectUnauthorized: false
    })
  }

  requestInterceptors = []
  responseInterceptors = []

  constructor () {
    this.cancelTokenSources = [] // 진행 중인 요청의 취소 토큰을 저장

    if (typeof window !== 'undefined' && window.indexedDB) {
      localforage.defineDriver(memoryDriver)

      forageStore = localforage.createInstance({
        driver: [
          localforage.INDEXEDDB,
        ],
        name: 'daiso-cache',
        storeName: 'ssnCache'
      })
    }
  }

  params (params, encodeFlag) {
    const newParams = {}
    for (const k in params) {
      if (typeof (params[k]) === 'string') {
        if (!encodeFlag) {
          newParams[k] = params[k]
        } else {
          newParams[k] = encodeURIComponent(params[k])
        }
      } else {
        newParams[k] = params[k]
      }
    }
    params = newParams
    this.config = this.resetConfig()
    this.config.params = params
    return this
  }

  data (data) {
    this.config = this.resetConfig()
    this.config.data = data
    return this
  }

  baseURL (baseURL) {
    this.config.baseURL = baseURL
    return this
  }

  contentType (contentType) {
    this.config.headers.contentType = contentType
    return this
  }

  addHeader (headerName, headerValue) {
    this.config.headers[headerName] = headerValue
    return this
  } // 싱글톤이니 절대 쓰지 말 것

  // arraybuffer, blob, document, json, text, stream
  responseType (responseType) {
    this.config.responseType = responseType
    return this
  }

  withCredentials (withCredentials) {
    this.config.withCredentials = withCredentials
    return this
  }

  setRequestInterceptors (beforeInterceptor, errorInterceptor) {
    this.requestInterceptors.push({
      before: (config) => {
        // Start the progress bar
        setTimeout(() => console.log('after'), 3000)
        if (typeof window !== 'undefined' && window.$nuxt) {
          window.$nuxt.$startLoading()
        }
        beforeInterceptor()
        return config
      },
      error: (error) => {
        // Finish the progress bar
        if (typeof window !== 'undefined' && window.$nuxt) {
          window.$nuxt.$finishLoading()
        }
        errorInterceptor()
        return Promise.reject(error)
      }
    })
    return this
  }

  setResponseInterceptors (beforeInterceptor, errorInterceptor) {
    this.responseInterceptors.push({
      before: (config) => {
        // Finish the progress bar
        setTimeout(() => console.log('after'), 3000)
        if (typeof window !== 'undefined' && window.$nuxt) {
          window.$nuxt.$startLoading()
        }
        beforeInterceptor()
        return config
      },
      error: (error) => {
        // Finish the progress bar
        if (typeof window !== 'undefined' && window.$nuxt) {
          window.$nuxt.$finishLoading()
        }
        errorInterceptor()
        return Promise.reject(error)
      }
    })
    return this
  }

  async removeExpiredCache (forageStore) {
    try {
      const keys = await forageStore.keys()

      const now = Date.now()

      for (const key of keys) {
        const cachedItem = await forageStore.getItem(key)
        if (cachedItem) {
          const { expires } = cachedItem
          if (expires && expires < now) {
            await forageStore.removeItem(key)
          }
        }
      }
    } catch (error) {
      console.error('Error retrieving keys:', error)
    }
  }

  async removeAllCache (forageStore) {
    try {
      const keys = await forageStore.keys()
      for (const key of keys) {
        await forageStore.removeItem(key)
      }
    } catch (error) {
      console.error('Error retrieving keys:', error)
    }
  }

  build () {
    const instance = axios.create(this.config)
    const buildConfig = Object.assign({}, this.config)

    this.removeExpiredCache(forageStore)
    // this.removeAllCache(forageStore)
    const cache = setupCache({
      maxAge: 10 * 60 * 1000,
      store: forageStore,
      clearOnStale: false,
      exclude: { query: false },
      debug: false,
    })
    instance.defaults.adapter = cache.adapter
    instance.defaults.baseURL = this.config.baseURL
    instance.interceptors.response.use((response) => {
      if (response.request.fromCache !== undefined) {
        response.fromCache = response.request.fromCache
      } else {
        response.fromCache = false
      }
      return response
    })

    if (buildConfig.data !== null && buildConfig.data !== undefined && buildConfig.data !== {}) {
      instance.interceptors.request.use((config) => {
        config.data = buildConfig.data
        return config
      })
    }

    instance.interceptors.response.use((config) => {
      return config
    })

    instance.interceptors.request.use((config) => {
      const cancelTokenSource = axios.CancelToken.source()
      this.cancelTokenSources.push(cancelTokenSource)
      config.cancelToken = cancelTokenSource.token
      // const show = window.$nuxt.$store.getters['loading/isShowLoadingBar']
      // const turnOffShow = window.$nuxt.$store.getters['loading/isTurnOffShowLoading']
      // async 시작 vuex 상태

      // async 끝 vuex 상태

      // if (!끝 vuex 상태){
      //   window.$nuxt.$store.commit('loading/setLoadingBar', true)
      // }

      // if (!turnOffShow) {
      //   if (!show) {
      //     window.$nuxt.$store.commit('loading/setLoadingBar', true)
      //   }
      // } else {
      //   window.$nuxt.$store.commit('loading/setLoadingBar', false)
      // }
      window.$nuxt.$store.commit('loading/incrementRequest')
      return config
    }, (error) => {
      // window.$nuxt.$store.commit('loading/setLoadingBar', false)
      return Promise.reject(error)
    })

    instance.interceptors.response.use((response) => {
      // window.$nuxt.$store.commit('loading/setLoadingBar', false)
      this._removeCancelToken(response.config.cancelToken)
      window.$nuxt.$store.commit('loading/decrementRequest')
      return response
    }, (error) => {
      if (axios.isCancel(error)) {
        // console.log('Request was cancelled:', error.message)
      } else {
        window.$nuxt.$store.commit('loading/decrementRequest')
        this._removeCancelToken(error.config?.cancelToken)
      }
      // window.$nuxt.$store.commit('loading/setLoadingBar', false)
      return Promise.reject(error)
    })

    this.requestInterceptors.forEach((interceptor) => {
      instance.interceptors.request.use(interceptor.before, interceptor.error)
    })

    this.responseInterceptors.forEach((interceptor) => {
      instance.interceptors.response.use(interceptor.before, interceptor.error)
    })

    return instance
  }

  resetConfig () {
    this.requestInterceptors = []
    this.responseInterceptors = []
    // this.setResponseInterceptors(this.defaultResposeBeforeInteceptor, this.defaultResposeErrorInteceptor)
    return {
      baseURL: process.env.API_SSN_BASE_URL2,
      headers: {},
      params: {},
      withCredentials: true
    }
  }

  cancelAllRequests (reason = 'Requests cancelled.') {
    this.cancelTokenSources.forEach(source => source.cancel(reason))
    try {
      window.$nuxt.$store.commit('loading/clearRequest')
    } catch (e) {}
    this.cancelTokenSources = []
  }

  _removeCancelToken (cancelToken) {
    this.cancelTokenSources = this.cancelTokenSources.filter(
      source => source.token !== cancelToken
    )
  }

  // 뒤로가기용 추가
  async fetchCachedResults (key) {
    try {
      const mergedData = []
      const matchingKeys = await this.findCachedKeys(key)
      if (matchingKeys.length === 0) {
        console.error('No matching keys found.')
        return null
      }
      for (const k of matchingKeys) {
        const cachedData = await forageStore.getItem(k)
        const results = cachedData?.data?.data?.resultSet?.result
        if (!Array.isArray(results) || results.length === 0) {
          console.warn(`No results found for key: ${k}`)
          continue
        }
        const resultDocuments = results[results.length > 1 ? 1 : 0]?.resultDocuments || []
        mergedData.push(...resultDocuments)
      }
      return { data: mergedData, lastKey: matchingKeys[matchingKeys.length - 1] }
    } catch (error) {
      console.error('Error fetching cached data:', error)
      return null
    }
  }

  async findCachedKeys (key) {
    const keyList = await forageStore.keys()
    return await keyList.filter(k => k.includes(key))
  }

  async clearCachedSearchResults (key) {
    let matchingKeys = []
    matchingKeys = await this.findCachedKeys(key)
    for (const k of matchingKeys) {
      forageStore.removeItem(k)
    }
  }
}
export default ({ $axios }, inject) => {
  inject('AxiosBuilderCache', new AxiosBuilderCache())
}
