// 공통 함수: apiVersion 변경 시 해당 캐시 초기화
import _ from 'lodash'
import axios from 'axios'
import memoryDriver from 'localforage-memoryStorageDriver'
import localforage from 'localforage'

let onlyFirst = true

function handleCache (apiCache, apiVersionFromLocalStorage, apiVersionFromS3, cacheType) {
  if (_.isEmpty(apiVersionFromLocalStorage)) {
    localStorage.setItem('apiVersion', apiVersionFromS3)
  } else if (!_.isEqual(apiVersionFromLocalStorage, apiVersionFromS3.toString())) {
    // api가 변경되었을 때
    console.log(`${cacheType} apiVersion이 변경되었습니다. ${cacheType}를 초기화합니다.`)
    clearCache(apiCache, cacheType) // 공통의 clearCache 함수 호출
    localStorage.setItem('apiVersion', apiVersionFromS3)
  }
}

// 공통 캐시 초기화 함수
function clearCache (cache, cacheType) {
  cache.keys().then((keys) => {
    if (keys.length > 0) {
      // 저장소에 데이터가 있으면, 모든 데이터를 삭제
      console.log(`${cacheType}에 데이터가 있습니다. 모든 데이터를 삭제합니다.`)
      cache.clear().then(() => {
        console.log(`${cacheType}에 저장된 모든 데이터를 삭제했습니다.`)
      }).catch((error) => {
        console.error(`${cacheType} 데이터를 삭제하는 중 오류 발생:`, error)
      })
    } else {
      // 저장소가 비어 있는 경우
      console.log(`${cacheType}는 비어 있습니다.`)
    }
  }).catch((error) => {
    console.error(`${cacheType} 저장소 확인 중 오류 발생:`, error)
  })
}

// 캐시 처리 함수 (postCache, ssnCache, homeCache 모두 통합)
function handleCacheForAll (cache, apiVersionFromLocalStorage, apiVersionFromS3, cacheType) {
  handleCache(cache, apiVersionFromLocalStorage, apiVersionFromS3, cacheType)
}

// 초기화로직 (최초진입시 비교, 페이지 이동시 비교)
async function firstOrRouteChange () {
  // pjm onlyFirst false일때 동작하는 캐시초기화 로직
  const uniqueKey = Date.now()
  const apiVersionFromLocalStorage = localStorage.getItem('apiVersion')
  const s3Url = process.env.S3_API_CACHE_URL + '/apiVersion.txt?v=' + uniqueKey
  const apiVersionFromS3 = await axios.get(s3Url, { timeout: 2000 }).then((res) => {
    return res.data
  }).catch((e) => {
    console.log(e)
  })
  if (apiVersionFromS3 && !_.isEqual(apiVersionFromLocalStorage, apiVersionFromS3.toString())) {
    // 다르면 초기화
    // 페이지이동시 캐시초기화
    localforage.defineDriver(memoryDriver)

    // 'ssnCache'가 'daiso-cache'라는 name 아래에 존재하는지 확인
    const ssnCacheItem = localforage.createInstance({
      driver: [localforage.INDEXEDDB],
      name: 'daiso-cache', // 데이터베이스 이름
      storeName: 'ssnCache' // 실제 저장소 이름
    })
    handleCacheForAll(ssnCacheItem, apiVersionFromLocalStorage, apiVersionFromS3, 'ssnCache')
  }
}

export default async ({ app, $axios, store }) => {
  await app.router.beforeEach(async (to, from, next) => {
    // 고객 Id가 있는 경우 GTM DataLayer Push
    window.dataLayer = window.dataLayer || []

    window.history.scrollRestoration = 'manual'

    const userId = store.getters['session/getMb']?.mbEid ?? ''

    if (userId) {
      window.dataLayer.push({
        userId
      })
    }

    const doNext = true
    console.log('route.js')

    if (onlyFirst) {
      onlyFirst = false
      if (typeof window !== 'undefined') {
        if (performance.getEntriesByType('navigation')[0].type === 'reload') {
          const ssnCacheItem = localforage.createInstance({
            driver: [localforage.INDEXEDDB],
            name: 'daiso-cache', // 데이터베이스 이름
            storeName: 'ssnCache' // 실제 저장소 이름
          })
          clearCache(ssnCacheItem, 'ssnCache')
        } else {
          firstOrRouteChange().then(() => {

          }).catch((err) => {
            console.error(err)
          })
        }
      }
    } else {
      // pjm onlyFirst false일때 동작하는 캐시초기화 로직
      firstOrRouteChange().then(() => {

      }).catch((err) => {
        console.error(err)
      })
    }

    // let isLogin = null
    // await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/token')
    //   .then((res) => {
    //     isLogin = res.success
    //   })
    //   .catch(() => { isLogin = false })
    //
    // if (!isLogin) {
    //   // 세션삭제
    //   store.commit('session/setMember', {})
    // } else {
    //   await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/getMbInfoByToken')
    //     .then((res) => {
    //       if (res.success) {
    //         store.commit('session/setMember', res.data)
    //       }
    //     })
    // }

    let eventType

    if (window.event !== undefined) {
      eventType = window.event.type.toUpperCase()
    } else {
      console.log('이벤트없음')
    }

    if (eventType !== undefined && eventType === 'POPSTATE') {
      store.commit('page/setPopstate', true)
    } else {
      store.commit('page/setPopstate', false)
    }

    if (doNext) {
      // 결제완료/결제실패 화면에서 뒤로가기 버튼 클릭시 장바구니 이동
      if (['/', '/or/ora/SCR_ORA_0006'].includes(from.path) && ['/or/ora/SCR_ORA_0001', '/or/ora/SCR_ORA_0007', '/fb/fba/SCR_FBA_0006', '/bp/bpa/SCR_BPA_0008'].includes(to.path)) {
        const orBackPath = localStorage.getItem('orBackPath')
        // console.log('◀■■ beforeEach::orBackPath', orBackPath)
        if (orBackPath) {
          await next({ path: orBackPath })
        } else {
          await next()
        }
      } else if (from.path === '/bp/bpa/SCR_BPA_0008' && to.path === '/bp/bpa/SCR_BPA_0001') { // 대량주문 주문서 뒤로가기 버튼 클릭시 장바구니 이동
        await next({ path: '/or/orc/SCR_ORC_0001?tab=04' })
      } else if (from.path === '/or/ORC/SCR_ORC_0001' && to.path === '/or/ora/SCR_ORA_0006') { // 장바구니에서 주문완료/실패 화면 이동
        await next(false)
      } else {
        await next()
      }
    } else {
      await next(false)
    }
  })

  // for groobee
  app.router.afterEach((to) => {
    const accountInfo = store.getters['session/getAccountInfo']
    const metaTags = [
      { hid: 'groobee:member_id', property: 'groobee:member_id', content: accountInfo?.mbMngNo ?? '' },
      { hid: 'groobee:member_grade', property: 'groobee:member_grade', content: '' },
      { hid: 'groobee:member_gender', property: 'groobee:member_gender', content: accountInfo?.sexdSe ?? '' },
      { hid: 'groobee:member_type', property: 'groobee:member_type', content: accountInfo?.userTy ?? '' },
      { hid: 'groobee:member_age', property: 'groobee:member_age', content: accountInfo?.ageGroup ?? '' }
    ]
    const currentMetaTags = app.head.meta
    metaTags.forEach((tag) => {
      const index = currentMetaTags.findIndex(existingTag => existingTag.hid === tag.hid)
      if (index !== -1) {
        currentMetaTags.splice(index, 1) // 기존 메타 태그 삭제
      }
    })
    app.head.meta = [...app.head.meta, ...metaTags]

    // const groobeeScript = document.getElementById('groobee-script')
    // if (groobeeScript) {
    //   groobeeScript.remove()
    // }
    switch (to.path) {
      case to.path.includes('/ds/exhCtgr/'):
      case '/bp/bpa/SCR_BPA_0008':
      case '/fb/fba/SCR_FBA_0006':
      case '/or/ora/SCR_ORA_0001':
      case '/or/ora/SCR_ORA_0006':
      case '/or/ora/SCR_ORA_0007':
      case '/or/orc/SCR_ORC_0001':
      case '/pd/pdr/SCR_PDR_0001':
        console.log('그루비 기타 페이지 수집 스크립트 예외 처리')
        break
      default: {
        // const script = document.createElement('script')
        // script.type = 'text/javascript'
        // script.id = 'groobee-script'
        // script.innerHTML = '(function(){groobee.start();})()'
        // document.head.appendChild(script)
        try {
          // eslint-disable-next-line no-undef
          groobee.start()
        } catch (e) {
          console.log(e)
        }
        break
      }
    }
  })
}
