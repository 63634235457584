import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })

export default ({ store, app }) => {
  createPersistedState({
    paths: ['session'],
    storage: {
      getItem: key => app.$cookiz.get(key),
      // setItem: (key, value) => app.$cookiz.set(key, value, { maxAge: 60 * 60 * 24 * 365, secure: true, sameSite: true, httpOnly: true }),
      setItem: (key, value) => app.$cookiz.set(key, value),
      removeItem: key => app.$cookiz.remove(key)
    }
  })(store)
  createPersistedState({
    key: 'local_store',
    paths: ['local'],
    storage: {
      getItem: key => window.localStorage.getItem(key),
      setItem: (key, value) => window.localStorage.setItem(key, value),
      removeItem: key => window.localStorage.removeItem(key),
    },
  })(store)
  createPersistedState({
    paths: ['ds.persisted'],
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })(store)
}
