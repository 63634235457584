import '@/assets/scss/index.scss'
import Vue from 'vue'
import VueClipBoard from 'vue-clipboard2'
import KakaoMap from '@/components/common/KakaoMap.vue'

import { commonfunc } from '~/mixin/common'

/**
 * packageName    : ms.msg
 * fileName       : SCR_MSG_0021
 * author         :
 * date           : 2023-08-28
 * description    : 판매매장상세
 * =============================================================
 * DATE             AUTHOR          NOTE
 * -------------------------------------------------------------
 * 2023-08-28       fekimsh         최초생성
 */
export default {
  components: {
    KakaoMap
  },
  mixins: [commonfunc],
  data () {
    return {
      outerVisible: false,
      strCd: null,
      str: {
        strNm: '',
        strZip: '',
        strAddr: '',
        strDtlAddr: '',
        opngTime: '',
        clsngTime: '',
        strTno: '',
        bassPkupStrYn: '',
        pkupYn: '',
        pntAcmYn: '',
        pntUseYn: '',
        elecRctwYn: '',
        parkYn: '',
        nmtkYn: '',
        phcYn: '',
        usimYn: '',
        phstkYn: '',
        nocashYn: '',
      }
    }
  },
  watch: {
    outerVisible (val) {
      if (val) {
        this.$AxiosBuilder
          .data({ inclusiveStrCd: this.strCd })
          .build()
          .post('/ms/msg/selStr')
          .then((res) => {
            if (res.data.data && res.data.data.length) {
              this.str = res.data.data[0]
              this.reload()
            }
          })
      }
    },
    'str' () {
      if (this.str) {
        this.reload()
      }
    }

  },
  mounted () {
  },
  methods: {
    copyAddr () {
      const fullAddr = this.str.strAddr + (this.str.strDtlAddr ? ' ' + this.str.strDtlAddr : '')
      VueClipBoard.config.autoSetContainer = true
      Vue.use(VueClipBoard)
      this.$copyText(fullAddr)
      this.$notify({
        message: '주소가 복사되었습니다.',
        position: 'bottom-left',
        showClose: false,
        duration: 5000,
        onClick: () => { this.$notify.closeAll() }
      })
    },
    callStr (strTno) {
      location.href = `tel:${strTno}`
    },
    // kakaoMap 컴포넌트 생성
    reload () {
      setTimeout(() => {
        this.$refs.kakaoMap.create()
      }, 1000)
    }
  }
}
