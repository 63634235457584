import _ from 'lodash'

/**
 * 이미지/비디오 주소를 cdn 주소로 변환한다.
 * 기존 URL에 CDN 주소가 포함되어있을 경우 추가하지 않도록 예외처리 하였음.
 * @constructor
 * @function
 * @param {string} mediaSrc - cdn 주소가 포함되지 않은 이미지/비디오 주소
 * @return {String} CDN 주소로 변환된 이미지/비디오 주소
 */
const getCdnUrl = (mediaSrc) => {
  let mediaUrl = process.env.WEB_BASE_URL + '/images/tempLy/noimage.png'

  if (mediaSrc) {
    if (mediaSrc.includes(process.env.CDN_BASE_URL)) {
      mediaUrl = mediaSrc
      return encodeURI(mediaUrl)
    }

    let url = process.env.CDN_BASE_URL
    url = (mediaSrc.charAt(0) !== '/' ? url + '/' : url) + mediaSrc
    mediaUrl = _.unescape(url)
  }

  return encodeURI(mediaUrl)
}

/**
 * 이미지 주소에 format, quality, resizing parameter를 적용한다.
 * @constructor
 * @function
 * @param {String} imageSrc - image URL
 * @param {String} imageResize - resize
 * @param {number} imageQuality -  image quality
 * @param {String} imageFormat - image format
 * @param {Boolean} isAutorotate - 자동회전 여부
 * @param {String} cropInfo - 좌상단을 기준으로 원하는 영역만큼 이미지를 잘라낸다. 영역은 width x height{+-}x{+-}y{%} 로 표현한다. 이미지 중앙을 기준으로 하고 싶은 경우 cropcenter명령어를 사용한다.
 * @return {String} 변환된 이미지 주소
 */
const getResizedImgUrl = (imageSrc, imageResize, imageQuality, imageFormat, isAutorotate, cropInfo) => {
  if (imageSrc) {
    let resultSrc = `${imageSrc}${
      isAutorotate ? '/dims/autorate/on' : ''
    }${
      imageResize ? `/dims/resize/${imageResize}` : ''
    }${
      imageQuality ? `/dims/quality/${imageQuality}` : ''
    }${
      imageFormat ? (imageFormat === 'avifWithWebp' ? '/dims/format/avif;fallback=webp' : `/dims/format/${imageFormat}`) : ''
    }${cropInfo ?? ''}`

    // 'optimize' 추가 시 undefined 방지
    if (!resultSrc.includes('optimize')) {
      resultSrc += '/dims/optimize'
    }

    // undefined 방지를 위해 조건 추가
    const optimizedResultSrc = getUniqueUrlWithWord(resultSrc, '/optimize') ?? resultSrc
    return getUniqueUrlWithWord(optimizedResultSrc, '/dims') ?? optimizedResultSrc
  } else {
    return null
  }
}

/**
 * 비디오 주소에 format, resizing parameter를 적용한다.
 * @constructor
 * @function
 * @param {String} videoSrc - video URL
 * @param {String} videoResize - resize
 * @Param {Boolean} fastStart - fastStart 추가여부
 * @param {String} videoFormat - video format
 * @param {String} trimRange - trim Range( ex)1-5 )
 * @return {String} 변환된 비디오 주소
 */
const getResizedVideoUrl = (videoSrc, videoResize, fastStart, videoFormat, trimRange) => {
  if (videoSrc) {
    return videoSrc +
    (videoResize
      ? '/xcdr/preset/_' + videoResize + 'p'
      : '') +
    (trimRange
      ? '/trim/' + trimRange
      : '') +
    (videoFormat === 'webm'
      ? '/xcdr/preset/_mp42webm/options/video.codec=vp9/.webm'
      : '') +
    (fastStart
      ? '/faststart/true'
      : '')
  } else {
    return null
  }
}

/**
 * 비디오에서 캡쳐된 이미지를 추출한다.
 * @constructor
 * @function
 * @param {String} videoSrc - Video URL
 * @param {String} imageFormat - 캡쳐될 이미지의 포맷
 * @param {Number,String} imageResize - 캡쳐될 이미지의 resize info
 * @param {Number, String} imageQuality - 캡쳐될 이미지의 quality
 * @param {Number} ts - 캡쳐할 이미지의 초
 * @return {String} 캡쳐될 이미지의 주소
 */
const getCapturedImageUrl = (videoSrc, imageFormat, imageResize, imageQuality, ts) => {
  if (videoSrc) {
    return videoSrc +
  '/xcdr/capture' +
  (ts
    ? '/ts=' + ts
    : '') +
   (imageFormat
     ? '/format=' + imageFormat
     : '') +
  (imageResize
    ? '/dims/resize/' + imageResize
    : '') +
  (imageQuality
    ? '/quality/' + imageQuality
    : '')
  } else {
    return null
  }
}

/**
 * 특정 키워드를 유일하게 만든다. (/dims, /optimize 등)
 * @constructor
 * @function
 * @param {String} src - Image/Video URL
 */
const getUniqueUrlWithWord = (url, word) => {
  const splitedUrl = url.split(word)
  let mediaUrl = ''
  for (let i = 0; i < splitedUrl.length; i++) {
    switch (i) {
      case 0:
        mediaUrl = splitedUrl[i]
        break
      case 1:
        mediaUrl += word + splitedUrl[i]
        break
      default:
        mediaUrl += splitedUrl[i]
        break
    }
  }
  return mediaUrl
}

export {
  getCdnUrl,
  getResizedImgUrl,
  getResizedVideoUrl,
  getCapturedImageUrl
}
